/* You can add global styles to this file, and also import other style files */
@charset "UTF-8"

@import '~viewerjs/dist/viewer.css'

@function unicode($str)
    @return unquote("\"")+unquote(str-insert($str, "\\", 1))+unquote("\"")

// max number of delays variable:
$max-nb-delays: 20

.openseadragon-container img
    padding: 3px!important

.card-toggle .chevron:after
    content: unicode("f139")
    float: right

.card-toggle.collapsed .chevron:after
    content: unicode("f13a")

.cursor-pointer
    cursor: pointer

.translate-in-from-top-delayed
    animation: 800ms translate-in-from-top
    animation-fill-mode: forwards
    animation-delay: 1500ms

.translate-in-from-left-delayed
    animation: 800ms translate-in-from-left
    animation-fill-mode: forwards
    animation-delay: 1500ms

.translate-in-from-bottom-delayed
    animation: 800ms translate-in-from-bottom
    animation-fill-mode: forwards
    animation-delay: 1500ms

.translate-in-from-right-delayed
    animation: 800ms translate-in-from-right
    animation-fill-mode: forwards
    animation-delay: 1500ms

.translate-in-from-top
    animation: 800ms translate-in-from-top

.translate-in-from-left
    animation: 800ms translate-in-from-left

.translate-in-from-bottom
    animation: 800ms translate-in-from-bottom

.translate-in-from-right
    animation: 800ms translate-in-from-right

.rotate-360-repeat
    animation: 20s infinite rotate-360

.rotate-360-repeat-fast
    animation: 20s infinite rotate-360-fast

.rotate-360-repeat-delayed-5s
    animation: 20s infinite rotate-360
    animation-delay: 5s

.rotate-360-repeat-delayed-10s
    animation: 20s infinite rotate-360
    animation-delay: 10s

.rotate-360-repeat-delayed-15s
    animation: 20s infinite rotate-360
    animation-delay: 15s

.rotate-in-from-behind
    animation: 800ms rotate-in-from-behind

.rotate-in-from-behind-delayed
    animation: 800ms rotate-in-from-behind
    animation-fill-mode: forwards
    animation-delay: 1500ms

.fade-in
    animation: 1000ms fade-in

.fade-in-delayed
    animation: 1000ms fade-in
    animation-fill-mode: forwards
    animation-delay: 1500ms

.fade-in-slow
    animation: 2000ms fade-in

.flash-topleft-once
    transform-origin: top left
    animation: 1s flash-scale
.flash-topright-once
    transform-origin: top right
    animation: 1s flash-scale
.flash-bottomleft-once
    transform-origin: bottom left
    animation: 1s flash-scale
.flash-bottomright-once
    transform-origin: bottom right
    animation: 1s flash-scale

.scale-out
    animation: 1s scale-out

.scale-in
    animation: 1s scale-in
.scale-in-topleft
    transform-origin: top left
    animation: 1s scale-in
.scale-in-topright
    transform-origin: top right
    animation: 1s scale-in
.scale-in-bottomleft
    transform-origin: bottom left
    animation: 1s scale-in
.scale-in-bottomright
    transform-origin: bottom right
    animation: 1s scale-in



@keyframes translate-in-from-top
    from
        transform: translate(0,-200%)
        opacity: 0
    to
        transform: translate(0,0)
        opacity: 1

@keyframes translate-in-from-left
    from
        transform: translate(-200%,0)
        opacity: 0
    to
        transform: translate(0,0)
        opacity: 1

@keyframes translate-in-from-bottom
    from
        transform: translate(0,200%)
        opacity: 0
    to
        transform: translate(0,0)
        opacity: 1

@keyframes translate-in-from-right
    from
        transform: translateX(100%)
        opacity: 0
    to
        transform: translateX(0)
        opacity: 1

@keyframes fade-in
    from
        opacity: 0
    to
        opacity: 1

@keyframes rotate-in-from-behind
    from
        transform: rotateY(0.5turn)
        opacity: 0
    to
        transform: rotateY(0)
        opacity: 1

@keyframes rotate-360
    0%
        transform: rotateY(0)
    5%, 100%
        transform: rotateY(1turn)

@keyframes rotate-360-fast
    0%
        transform: rotateY(0)
    2%, 100%
        transform: rotateY(1turn)

@keyframes flash-scale
    0%
        transform: scale(1)
    50%
        transform: scale(2)
    100%
        transform: scale(1)

@keyframes scale-in
    0%
        transform: scale(50)
    100%
        transform: scale(1)

@keyframes scale-out
    0%
        transform: scale(0)
    100%
        transform: scale(1)

@for $i from 0 through $max-nb-delays
    .translate-in-from-left-delayed-#{$i}
        animation: 800ms translate-in-from-left
        animation-fill-mode: forwards
        animation-delay: 100ms*$i

@for $i from 0 through $max-nb-delays
    .translate-in-from-right-delayed-#{$i}
        animation: 800ms translate-in-from-right
        animation-fill-mode: forwards
        animation-delay: 100ms*$i

@for $i from 0 through $max-nb-delays
    .translate-in-from-left-fast-delayed-#{$i}
        animation: 500ms translate-in-from-left
        animation-fill-mode: forwards
        animation-delay: 25ms*$i

@for $i from 0 through $max-nb-delays
    .translate-in-from-right-fast-delayed-#{$i}
        animation: 500ms translate-in-from-right
        animation-fill-mode: forwards
        animation-delay: 25ms*$i

.astro-tagline
    color: #fcdb5e

.astro-details
    color: #e96d08

/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap'

$instagram: #c13584
$pdf: #7A8CFF
$offer: #DA3367
$brownI: #A7967F

@import "../node_modules/bootstrap/scss/functions"
@import "../node_modules/bootstrap/scss/variables"
@import "../node_modules/bootstrap/scss/mixins"

$custom-colors: ("instagram": $instagram, "pdf": $pdf, "brownI": $brownI)
$theme-colors: map-merge($theme-colors, $custom-colors)

.btn-instagram.btn
    @include button-variant($instagram, $instagram)

.btn-outline-instagram.btn
    @include button-outline-variant($instagram)

.btn-pdf.btn
    @include button-variant($pdf, $pdf)

.btn-outline-pdf.btn
    @include button-outline-variant($pdf)

.btn-offer.btn
    @include button-variant($offer, $offer)

.btn-outline-offer.btn
    @include button-outline-variant($offer)

.btn-brownI.btn
    @include button-variant($brownI, $brownI)

.btn-outline-brownI.btn
    @include button-outline-variant($brownI)
